import React from 'react'
import TypewriterComponent from 'typewriter-effect'

export const Header = props => {
  return (
    <header id='header'>
      <div className='intro'>
        <div className='overlay'>
          {/* <div className='container'> */}
          {/* <div className='row'> */}
          <div className='intro-text'>
            <h1>{props.data ? props.data.title : 'Loading'}</h1>
            <p>
              <TypewriterComponent
                options={{
                  autoStart: true,
                  loop: true,
                  delay: 50,
                  pauseFor: 2000,
                  strings: [
                    'A whole new you!',
                    'Hairstyling and beauty redefined!',
                    'Your ultimate hair and beauty destination!',
                    'Press book now to give us a call...'
                  ]
                }}
              />
            </p>
            <a
              href='tel:07774243142'
              className='btn btn-custom btn-lg page-scroll'>
              <i className='fa fa-phone'></i> Book Now
            </a>
            <div className='opening-hours'>
              {props.data ? props.data.hours : 'Loading'}
            </div>
          </div>
          {/* </div> */}
          {/* </div> */}
        </div>
      </div>
    </header>
  )
}
