import React from 'react'

export const Services = props => {
  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Our Services</h2>
        </div>
        {props?.data?.map((parent, i) => (
          <div className='services-row'>
            <div key={`${parent?.heading}-${i}`}>
              <div className='title'>{parent?.heading}</div>
              <div className='row service-data'>
                {parent?.list.map((child, i) => (
                  <div key={`${child?.name}-${i}`} className='col-md-4 '>
                    <div>
                      <div className='service-desc'>
                        <div className='service-name'>{child?.name}</div>
                        <div className='service-price'>{child?.price}</div>
                      </div>
                      <div className='service-desc'>{child?.text}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
